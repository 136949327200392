.App {
  text-align: center;
}

.white-font {
  color: azure;
}

.app-profile-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  .app-profile-user-name {
    font-size: 20px;
    font-weight: 600;
    width: 60%;
    text-align: left;
  }
}
