.home-tile-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
  }
}
